@charset "utf-8";
@import "./variables.scss";
@import "./base.scss";

.header {
  height: $headerHeight;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  position: relative;
  background-color: $theme;

  .menu {
    span {
      &.material-icons {
        color: $pureWhite;
        font-size: 40px;
      }
    }
  }

  .logo {
    width: 200px;
    object-fit: contain;
    margin: 0 0 0 20px;
  }

  .exit {
    background: $danger;
    border: 1px $danger solid;
    padding: 0 10px;
    align-self: center;
    height: 50px;
    display: flex;
    align-items: center;

    span {
      margin: 0 5px;
      font-size: 14px;
      font-weight: bold;

      &.icon {
        font-size: 24px;
      }
    }

    &:hover span {
      color: $danger-text;
    }
  }
}

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 30px;
  flex-grow: 1;
  color: $black;

  > .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.loggedIn {
    background: $white;
    height: calc(100vh - #{$headerHeight});

    > .content {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  background: $pureWhite;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  th,
  td {
    border-bottom: 1px $theme-dark solid;
    padding: 10px 15px;
    font-size: 14px;

    &:first-child {
      padding: 10px 15px 10px 25px;
    }

    &:last-child {
      padding: 10px 25px 10px 15px;
    }

    &.thead {
      text-align: left;
    }
  }

  th {
    font-weight: 600;
  }

  td {
    &.thead {
      text-align: left;
    }
  }

  tr {
    &.has-pointer td {
      cursor: pointer;
    }
    &:hover {
      background: $white;
    }
    &.thead {
      text-align: left;
    }
    &:last-child {
      td {
        border: 0;
      }
    }
  }
}

.msg {
  border: 1px solid;
  padding: 10px;
  margin: 10px 0 0;

  &.error {
    border-color: $danger;
    color: $danger-text;
    background: pink;
  }
}

.checkTD {
  cursor: pointer;
  width: 50px;
  text-align: center;

  input {
    cursor: pointer;
  }
}

.full {
  width: 100%;
  height: 100%;
}

.appVersion {
  position: fixed;
  bottom: 0;
  right: 0;
  color: $white;
  font-weight: bold;
  margin: 10px;

  &.loggedIn {
    color: $theme-dark;
  }
}
