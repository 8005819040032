@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {margin: 0; padding: 0;}
*, ::before, ::after {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}

html {overflow: hidden;}
body {font-family: $default-font; font-size: 12px; color: $black; background: $light-gray;}
input, textarea, button {font-size: 12px; color: $black;}
strong, b {font-weight: 700;}
a {color: $theme; text-decoration: none;}
:focus {outline: 0;}

.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 18px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  }

.row {
  flex-direction: row !important;
}

.column {
  flex-direction: column !important;
}

/** Editor */
.ck-editor__editable_inline {
  min-height: 200px;
}

.ck-editor p,
.ck-editor span {
  color: #707070 !important;
  font-size: 15px !important;
}

.ck-editor li {
  line-height: 1.3;
}

.ck-editor ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 10px;
}

.ck-editor ol {
  list-style-type: decimal;
  list-style-position: inside;
  margin-top: 10px;
}

.ck-editor ul ul,
.ck-editor ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}

.ck-editor ol ol,
.ck-editor ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}

.ck-editor p,
.ck-editor li {
  font-size: 15px;
  line-height: 1.2rem;
}

.ck-editor p {
  margin-top: 15px;
}

.ck-editor em {
  font-style: italic;
}

.ck-editor .ck.ck-label {
  display: none;
}