@import "../../styles/variables.scss";

.button {
  border: 0;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.2s;

  &:disabled {
    cursor: default;
    background: rgba($gray, 0.5) !important;
    border-color: rgba($gray, 0) !important;

    .button__text {
      color: $pureWhite !important;
    }
  }

  &__text {
    color: $pureWhite;
    font-weight: bold;
    transition: all 0.2s;
  }

  &--primary {
    background: $theme;
    border: 2px $theme solid;
    color: $pureWhite;

    &:hover {
      background: $pureWhite;
      color: $theme;

      .button__text {
        color: $theme;
      }
    }
  }

  &--secondary {
    background: $secondary;
    border: 2px $secondary solid;

    &:hover {
      background: $pureWhite;

      .button__text {
        color: $secondary;
      }

      svg circle {
        fill: $secondary;
      }
    }
  }

  &--orange {
    background: $orange;
    border: 2px $orange solid;

    &:hover {
      background: $pureWhite;

      .button__text {
        color: $orange;
      }
    }
  }

  &--orange-dark {
    background: $orange-dark;
    border: 2px $orange-dark solid;

    &:hover {
      background: $pureWhite;

      .button__text {
        color: $orange-dark;
      }
    }
  }

  svg {
    display: block;
    max-width: 100%;
    max-height: 80%;
    margin: auto;
  }
}
