@import "../../styles/variables.scss";

.login {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  height: 400px;
  align-items: center;

  .logo {
    width: 300px;
    margin: 0 0 50px;
  }

  > span {
    font-size: 22px;

    &.title {
      color: $black;
      font-weight: 500;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
      width: 100%;
      height: 60px;
      padding: 15px 20px;
      margin: 15px 0 0;
      border: 1px $theme-light solid;
      color: $theme-light;
      background: $background;
      font-size: 18px;
      border-radius: 10px;

      ::placeholder {
        color: $theme-light;
        opacity: 1;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      button {
        width: 220px;
        height: 60px;
        margin: 0 15px 0 0;

        span {
          font-size: 19px;
        }
      }
    }
  }
}
