@import "../../styles/variables.scss";

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
  border-bottom: 2px $theme-dark solid;
  padding: 0 0 10px;

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .buttons {
    display: flex;

    .filter {
      width: auto;
      margin: 0;

      input,
      select {
        height: 100%;
      }
    }

    button {
      padding: 5px 10px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      font-weight: bold;

      span {
        &.material-icons {
          margin-right: 7px;
        }
      }
    }
  }
}
