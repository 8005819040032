@import "../../styles/variables.scss";

.menu {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  margin: 0 25px;
  max-width: 1200px;

  a {
    display: flex;
    padding: 10px;
    border-radius: 35px;

    &.selected {
      background: rgba(218, 218, 218, 0.37);
    }

    svg {
      height: 40px;
      fill: $pureWhite;
    }
  }
}
