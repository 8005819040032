@import "../../styles/variables.scss";

.welcome {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    font-size: 24px;
    font-weight: 600;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}
