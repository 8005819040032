$theme:rgb(79, 11, 133);
$theme-dark: #39006d;
$theme-light: #6b00b1;
$secondary: #ff4200;
$background: #eeeeee;
$orange: #F16522;
$orange-dark: #C14B27;
$danger: #A33;
$danger-text: #A33;
$success: #3A3;
$success-text: #3A3;
$gray: #999999;
$dark-gray: #666;
$light-gray: #eeeeee;
$white: #e7e7e7;
$pureWhite: #ffffff;
$black: #000000;
$background-horizontal: linear-gradient(135deg, $theme 0%, $theme-dark 80%);

$default-font: 'Open Sans', 'Roboto', sans-serif;

$width: 100%;
$gutter: 0;

$breakpoint-phone: 320px;
$breakpoint-tablet: 728px;
$breakpoint-desktop: 1024px;
$breakpoint-intermediate: 1280px;

$breakpoint-default: $breakpoint-desktop;

// 0 - 727
$small:(max-width: $breakpoint-tablet - 1);

// 728 - 1023
$medium: "(min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1})";

// 1024
$large: (min-width: $breakpoint-desktop);

// 320 up
$small-up: (min-width: $breakpoint-phone);

// 728 up
$medium-up: (min-width: $breakpoint-tablet);

// 1023 down
$large-down: (max-width: $breakpoint-desktop - 1);

// 1024 up
$large-up: (min-width: $breakpoint-desktop);

//1280 down
$intermediate-down: (max-width: $breakpoint-intermediate);

$headerHeight: 95px;
$contentHeight: calc(100vh - #{$headerHeight});
