@import "../../styles/variables.scss";

.file-uploader {
  display: flex;
  align-items: center;

  .button {
    width: 150px;
    height: 30px;
    border-radius: 5px;
  }

  p {
    width: 100%;
    white-space: nowrap;
    color: $theme;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
  }

  input[type="file"] {
    display: none;
  }
}
