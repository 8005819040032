@import "../../styles/variables.scss";

.error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0 0 20px;
  }

  p {
    margin: 0 0 10px;
  }

  .content {
    font-size: 20px;
  }
}
