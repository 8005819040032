@import "../../styles/variables.scss";

.crud {
  width: 100%;

  .head {
    button {
      margin: 0 0 0 10px;
    }
  }

  .form {
    p.loading {
      font-size: 20px;
      text-align: center;
    }

    label {
      padding: 0 10px;
      height: 38px;
      font-weight: bold;
      display: flex;
      align-items: flex-start;
    }

    input,
    .link,
    select,
    textarea {
      background: $pureWhite;
      line-height: 30px;
      height: 30px;
      padding: 3px 5px;
      border: 1px $gray solid;
      margin: 0 0 10px;
      text-align: left;
      align-self: flex-start;
      min-width: 0;
      border-radius: 3px;
      grid-area: field;
    }

    .link {
      display: flex;
      align-items: center;
    }

    textarea {
      height: 40px;
    }

    input[type="checkbox"],
    input[type="radio"] {
      width: 20px;
      border: 0;
      align-self: center;
      justify-self: flex-start;
    }

    [readonly],
    [disabled] {
      background: #DDD;
    }

    label.error {
      color: $danger-text;
      text-align: left;
      padding: 0;
    }

    .r {
      position: relative;
      display: grid;
      grid-template-areas:
        "label field"
        "label error";
      grid-template-columns: 200px 1fr;
      float: left;
      clear: both;
      width: 100%;
      margin: 0 0 15px;

      &:last-child {
        input,
        select {
          margin-bottom: 0;

          & + label.error {
            margin-top: 15px;
          }
        }

        input.error,
        select.error {
          margin-bottom: 25px;
        }
      }

      label {
        text-align: right;
        grid-area: label;

        b.req {
          color: $danger-text;
          font-weight: bold;
          margin: 0 0 0 3px;
        }

        &:first-child {
          min-width: 200px;
          padding: 0 10px 0 0;
          text-align: left;
        }

        &.error {
          width: auto;
          white-space: normal;
          line-height: normal;
          height: auto;
          grid-area: error;
        }
      }

      select[multiple] + label.error {
        top: auto !important;
        bottom: 8px;
      }

      hr {
        width: 100%;
        margin: 10px 0 20px;
      }

      .react-datepicker__triangle {
        transform: translate(20px) !important;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background: $theme;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background: $theme-dark;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: $theme-dark;
      }
    }
  }
}
