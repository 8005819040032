@import "../../styles/variables.scss";

$thumbSize: 240px;

.list {
  width: 100%;

  .filter {
    width: 50%;
    margin: 0 0 20px;
    display: flex;
    align-items: center;

    label {
      font-size: 16px;
      font-weight: bold;
    }

    input,
    select {
      height: 40px;
      flex-grow: 1;
      border: 1px $theme-dark solid;
      margin: 0 10px 0 20px;
      font-size: 14px;
      padding: 0 10px;
      border-radius: 25px;
    }
  }

  > p {
    font-size: 20px;
    text-align: center;
  }

  .table {
    td {
      &.actions {
        width: 100px;
        text-align: center;
        padding: 10px 0;
      }
    }
  }

  .wrapButtons {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 3px;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;

      .buttonText {
        font-weight: normal;
      }

      &.delete {
        background: $danger;
        border-color: $danger;

        &:hover {
          background: $pureWhite;
          color: $danger;
        }
      }
    }
  }

  .gallery {
    height: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(#{$thumbSize}, 1fr));

    .image {
      background: $pureWhite;
      padding: 20px;
      width: $thumbSize;
      height: $thumbSize;
      position: relative;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      border: 1px $theme solid;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
      }

      .info {
        margin: 10px 0 0;
        font-weight: 600;

        .name {
          display: flex;
          justify-content: space-between;
        }

        .status {
          display: flex;
          align-items: center;

          &.active {
            .icon {
              color: $success;
            }
          }

          .icon {
            font-weight: bold;
            color: $danger;
          }
        }

        > span {
          display: block;
        }
      }

      .wrapButtons {
        position: absolute;
        border-left: 1px $theme solid;
        border-bottom: 1px $theme solid;
        background: $pureWhite;
        padding: 5px;
        top: 0;
        right: 0;
        border-radius: 0 0 0 15px;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 10px 0 0;

    button {
      padding: 5px 8px;
      margin: 0 5px;
      font-size: 12px;

      .button__text {
        font-family: inherit;
      }

      &.disabled {
        opacity: 0.3;
      }
    }
  }
}
